<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    acceso
                </div>

                <v-form ref="form" autocomplete="off">
              
                    <!-- FORMULARIO ACCESO -->
                    <v-row class="px-7">
                        <v-col class="mt-4" cols="12" sm="6" md="4" lg="4" xl="4">
                            <v-text-field class="mb-n7" outlined dense text
                                v-model="acceso.Tarjeta" label="Tarjeta">
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="6" md="4" lg="4" xl="4">
                            <v-text-field class="mb-n7" outlined dense text
                                v-model="acceso.Nombre" label="Nombre">
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="6" md="4" lg="4" xl="4">
                            <v-text-field class="mb-n7" outlined dense text
                                v-model="acceso.Dni" label="DNI">
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="6" md="4" lg="4" xl="4">
                            <v-text-field class="mb-n7" outlined dense text
                                v-model="acceso.Compania" label="Nombre de la compañia">
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="6" md="4" lg="4" xl="4">
                            <v-text-field class="mb-n7" outlined dense text
                                v-model="acceso.Cif" label="CIF">
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="6" md="4" lg="4" xl="4">
                            <v-text-field class="mb-n7" outlined dense text
                                v-model="acceso.Departamento" label="Departamento">
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="6" md="4" lg="4" xl="4">
                            <DirFind 
                                label="Tienda" ref="dirCtrl" class="mb-n4" v-model="dirs"
                                multiple smallChips deletableChips>
                            </DirFind>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="6" md="8" lg="8" xl="8">
                            <v-text-field class="mb-n7" outlined dense text
                                v-model="acceso.Motivo" label="Motivo">
                            </v-text-field>
                        </v-col>
                        <v-col class="mt-4 mb-n7" cols="6" sm="6" md="4" lg="4" xl="4">
                            <DateFind label="Fecha desde" class="mb-n4" v-model="acceso.FechaDesde"></DateFind>
                        </v-col>
                        <v-col class="mt-4 mb-n7" cols="6" sm="6" md="4" lg="4" xl="4">
                            <DateFind label="Fecha hasta" class="mb-n4" v-model="acceso.FechaHasta"></DateFind>
                        </v-col>
                    </v-row>

                    <!-- Tabla detalles -->
                    <v-row v-if="!isNew">
                        <v-col class="px-7 mt-4" cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="dataRows"
                                :options.sync="options"
                                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100],disablePagination:loading, disableItemsPerPage: loading}"
                                :loading="loading"
                                style="overflow: scroll"
                                mobile-breakpoint="0"
                                class="elevation-1"
                                dense
                                >
                                <!-- eslint-disable-next-line vue/valid-v-slot -->
                                <template v-slot:item.Fecha="{ item }">
                                    {{ formatDate(item.Fecha) }}
                                </template>
                                <!-- eslint-disable-next-line vue/valid-v-slot -->
                                <template v-slot:item.Tipo="{ item }">
                                    {{ formatTipo(item.Tipo) }}
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                    <!-- ACEPTAR / CANCELAR / BORRAR -->
                    <v-row class="mt-2" justify="center">
                        <v-btn
                            :loading="loading"
                            @click="onBtnSave"
                            color="primary"
                            class="mt-2 mb-4 mr-2"
                            style="width:100px">
                            Aceptar
                        </v-btn>
                        
                        <v-btn
                            class="mt-2 mb-4 mr-2"
                            style="width:100px"
                            @click="goToList">
                            Cancelar
                        </v-btn>

                        <v-btn v-if="!isNew"
                            color="error"
                            class="mt-2 mb-4"
                            style="width:100px"
                            :disabled="loading || dataRows.length > 0"
                            @click="onBtnShowDeleteModal()">
                            Borrar
                        </v-btn>
                    </v-row>
              </v-form>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR ACCESO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el acceso? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import funcs from "../../funcs";
import DateFind from "../../components/DateFind.vue";
import DirFind from "../../components/DirFind.vue";
export default ({
    components: {
        DateFind, DirFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz', 'esDepartamento'])
    },
    data () {
        return {
            loading: false,
            isNew: true,
            showDeleteDialog: false,
            acceso: {
                AccesoExternoId: 0
            },
            dirs: [],
            // Tabla detalles
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Fecha', width:100, sortable: false, value: 'Fecha' },
                { text: 'Tienda', width:200, sortable: false, value: 'Dir.DirNom' },
                { text: 'Observaciones', width:300, sortable: false, value: 'Observaciones' },
                { text: 'Tipo', width:100, sortable: false, value: 'Tipo' }
            ],
            // End Tabla detalles
        }
    },
    // watch: {
    //   acceso: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
        if (!this.esDepartamento) { this.$router.push('/'); return; }
        this.isNew = this.$route.meta.isNew;
        
        if(!this.isNew) {
            this.acceso.AccesoExternoId = this.$route.params.id;
            this.loadData();
        }
    },
    methods: {
        goToList() {
            this.$router.push('/accesos-externos');
        },

        //#region Llamadas a la API
        getDataFromApi() {
            this.loading = true;
            return new Promise((resolve) => {
            const controllerParameters = {
                AccesoExternoId: this.acceso.AccesoExternoId
            };       
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/acceso-externo/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                const item = result.data.Acceso;

                setTimeout(() => {
                    this.loading = false;
                    resolve({
                        item
                    })
                }, 1000)
            })
            .catch(error => {
                if(error != null) {
                    this.loading = false;
                    alert('Error al cargar el acceso.');
                    this.goToList();
                }
            });
            })
        },
        postDataToApi() {
            this.loading = true;

            const dirIds = this.dirs.map(m => m.DirId);

            return new Promise((resolve) => {
            const controllerParameters = {
                AccesoExternoId: this.acceso.AccesoExternoId,
                Acceso: this.acceso,
                PerId: this.perId,
                DirIds: dirIds
            };
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/acceso-externo/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                const item = result.data.Acceso;

                setTimeout(() => {
                    this.loading = false;
                    resolve({
                        item
                    })
                }, 1000)
            })
            .catch(error => {
                if(error != null) {
                    this.loading = false;
                    alert('Error al guardar el acceso.');
                }
            });
            })
        },
        deleteDataFromApi() {
            this.loading = true;
            return new Promise((resolve) => {
            const controllerParameters = {
                AccesoExternoId: this.acceso.AccesoExternoId
            };       
            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": this.urlRaiz + "/api/acceso-externo/delete", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result => {
                const item = result.data;

                setTimeout(() => {
                    this.loading = false;
                    resolve({
                        item
                    })
                }, 1000)
            })
            .catch(error => {
                if(error != null) {
                    this.loading = false;
                    alert('Error al borrar el elemento.');
                }
            });
            })
        },
        //#endregion

        //#region Carga de datos  
        loadData() {
            this.getDataFromApi()
            .then(data => {
                if(data.item == null) {
                    alert('Acceso no encontrado.');
                    this.goToList();
                    return;
                }

                this.acceso = data.item;
                this.acceso.FechaDesde = this.formatDateInput(this.acceso.FechaDesde);
                this.acceso.FechaHasta = this.formatDateInput(this.acceso.FechaHasta);
                this.dirs = this.acceso.Dirs;
                this.dataRows = this.acceso.AccesosExternosDetalles;
            });
        },
        //#endregion

        //#region Click guardado
        onBtnSave() {
            // this.validate();
            // if(!this.isValid) { return; }
            
            this.postDataToApi().then(data => {
                if(data.item.AccesoExternoId != null) {
                    this.goToList();
                }
                else {
                    alert('Error al guardar el acceso.');
                }
            });
        },
        //#endregion

        //#region Click borrar
        onBtnDelete() {
            this.deleteDataFromApi().then(data => {
                if (data.item.Success) {
                    this.showDeleteDialog = false;
                    this.goToList();
                }
                else {
                    alert(data.item.Message);
                }
            });
        },
        //#endregion

        //#region Gestión de modales
        onBtnShowDeleteModal() {
            this.showDeleteDialog = true;
        },
        onBtnCancelDelete() {
            this.showDeleteDialog = false;
        },
        //#endregion

        //#region Validar formulario
        //   validate() {
        //     this.$refs.form.validate();
        //   }
        //#endregion
        formatDateInput(value)
        {
            const date = new Date(value);
            return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0')  + '-' + String(date.getDate()).padStart(2, '0');
        },
        formatDate(value) {
            return funcs.formatDate(value, true);
        },
        formatTipo(value) {
            const tipoOptions = [
                { label: 'ENTRADA', value: 0 },
                { label: 'SALIDA', value: 1 }
            ];
            const result = tipoOptions.find(f => f.value === value);
            if (result != null) return result.label;
            return '';
        }
    } 
})
</script>
